import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import CalendarNumber from '@/components/icons/CalendarNumber'
import ClockPrakerja from '@/components/icons/ClockPrakerja'
import Notes from '@/components/icons/Notes'
import Task from '@/components/icons/Task'
import NotFound from '@/components/illustration/404'
import { formatDate, showVueToast } from '@/utils'
const AbsensiField = () => import(/* webpackChunkName: "absensi-form" */ '@/views/Prakerja/AbsensiField')
const QuestionModal = () => import(/* webpackChunkName: "question-modal" */ '@/views/Prakerja/QuestionModal')
const UploadTugasModal = () => import(/* webpackChunkName: "upload-tugas-modal" */ '@/views/Prakerja/UploadTugasModal')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
export default {
  name: 'my-class-modal',
  filters: {
    formatDate
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  data() {
    return {
      dataAbsensi: {},
      visibleModalAbsensi: false,
      visibleComplainModal: false,
      visibleQuestionModal: false,
      visibleVideo: false,
      isTugasModalVisible: false,
      question: '',
      examHistory: [],
      classStatus: [
        {
          progress: 0,
          value: 'Menunggu Kelas'
        },
        {
          progress: 25,
          value: 'Mengikuti Kelas'
        },
        {
          progress: 50,
          value: 'Exam dan Evaluasi'
        },
        {
          progress: 75,
          value: 'Sertifikat G2 Academy'
        },
        {
          progress: 100,
          value: 'Pelaporan ke Ecommerce'
        }
      ],
      classProgressStepCount: 5,
      dataIsReady: false,
      isFetching: true,
      imageUrl: '',
      instructorList: [],
      certificateList: []
    }
  },
  components: {
    AbsensiField,
    QuestionModal,
    UploadTugasModal,
    CalendarNumber,
    ClockPrakerja,
    Notes,
    Task,
    NotFound,
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Attachment: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Attachment'),
    AlertCircle: () => import(/* webpackChunkName: "icon" */ '@/components/icons/AlertCircle'),
    CheckMarkCircle: () => import(/* webpackChunkName: "icons" */ '@/components/icons/CheckMarkCircle'),
    G2Academy: () => import(/* webpackChunkName: "icon" */ '@/components/icons/G2Academy'),
    Telegram: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Telegram'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    ActivityCard: () => import(/* webpackChunkName: "ActivityCard" */ '@/components/prakerja/ActivityCard'),
    InformationCard: () => import(/* webpackChunkName: "InformationCard" */ '@/components/prakerja/InformationCard')
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('prakerja', ['myClassDetail']),
    classId() {
      return this.$route.params.id
    },
    activities() {
      return this.myClassDetail?.schedule?.slice(0, -1)
    },
    lastActivityIndex() {
      return this.myClassDetail?.schedule?.length - 2
    },
    consultation() {
      return this.myClassDetail?.schedule?.slice(-1)[0]
    },
    consultationIndex() {
      return this.myClassDetail?.schedule?.length - 1
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['getMyClassDetail', 'sendDropboxQuestion']),
    ...mapActions('instructor', ['getInstructorById']),
    showTugasModal() {
      this.isTugasModalVisible = true
    },
    closeTugasModal() {
      this.isTugasModalVisible = false
    },
    handleMutateQuestion(value) {
      this.question = value
    },
    closeAndRefetch() {
      this.closeTugasModal()
      this.getDetail()
    },
    sendQuestion() {
      if (this.question === '') {
        showVueToast('Pertanyaan tidak boleh kosong!', 'error', 3000)
        return
      }
      this.showLoading()
      this.sendDropboxQuestion({
        id: this.classId,
        payloads: {
          question: this.question
        }
      })
        .then(() => {
          showVueToast('Pertanyaan berhasil dikirim!', 'success', 3000)
          this.hideLoading()
        })
        .catch(() => {
          showVueToast('Pertanyaan gagal dikirim!', 'error', 3000)
          this.hideLoading()
        })
      this.question = ''
      this.toggleQuestionModal()
    },
    getAbsenStatus(item) {
      if (this.myClassDetail.customer_id === 1 && item.video_url) {
        return 'Sudah Menonton'
      } else if (this.myClassDetail.customer_id === 1 && item.materi) {
        return 'Sudah Membaca'
      } else {
        return 'Absen Masuk'
      }
    },
    validityCheck(index) {
      if (index < 1) {
        return this.myClassDetail.pretest_status
      } else {
        return this.myClassDetail.schedule[index - 1].quiz_value >= 80
      }
    },
    getProgress(step) {
      return (step / this.classProgressStepCount) * 100
    },
    getDetail() {
      this.showLoading()
      this.isFetching = true
      this.getMyClassDetail({
        classId: this.classId
      })
        .then((res) => {
          this.imageUrl = res.customer_logo_url ? res.customer_logo_url : ''
          this.certificateList = res.certificates
          const schedule = res.schedule
          if (schedule.length > 0) {
            const instructorId = res.java_instructur_id_array
            if (instructorId.length > 0) {
              for (let i = 0; i < instructorId.length; i++) {
                this.getInstructor(instructorId[i])
              }
            }
            this.dataIsReady = true
            this.isFetching = false
            this.hideLoading()
          } else {
            this.dataIsReady = false
            this.isFetching = false
            this.hideLoading()
          }
        })
        .catch(() => {
          this.dataIsReady = false
          this.isFetching = false
          this.hideLoading()
        })
    },
    getInstructor(id) {
      this.getInstructorById({
        id: id
      })
        .then((res) => {
          // this.dataIsReady = true
          // this.isFetching = false
          this.hideLoading()
          if (res) this.instructorList.push(res)
        })
        .catch(() => {
          // this.dataIsReady = true
          // this.isFetching = false
          this.hideLoading()
        })
    },
    getExam() {
      this.getExamHistory({
        classId: this.classId,
        success: (res) => (this.examHistory = res)
      })
    },
    close() {
      this.$emit('close')
    },
    getDate(start, end) {
      const formattedStart = this.$options.filters.formatDate(start)
      const formattedEnd = this.$options.filters.formatDate(end)
      if (formattedStart === formattedEnd) {
        return formattedStart
      } else {
        return formattedStart + ' - ' + formattedEnd
      }
    },
    getHour(start, end) {
      const startTime = dayjs(String(start)).format('HH:mm')
      const endTime = dayjs(String(end)).format('HH:mm')
      return `${startTime} - ${endTime} (Waktu lokal anda)`
    },
    isValidToStartQuiz(scheduleEndTime) {
      const time = dayjs.duration('00:10:00')
      const endTime = dayjs(scheduleEndTime)
      endTime.subtract(time)
      const currentTime = dayjs(new Date())
      return dayjs(currentTime).valueOf() >= dayjs(endTime).valueOf()
    },
    getTime(input) {
      const date = this.$options.filters.formatDate(input)
      const time = dayjs(input).format('HH:mm')
      return date + ' ' + time
    },
    checkDate(start) {
      const startInMs = new Date(start).getTime()
      const current = new Date().getTime()
      return current < startInMs
    },
    successAbsen() {
      this.getDetail()
      this.closeModalAbsensi()
    },
    openAbsensi(value, state, classId) {
      this.dataAbsensi = {
        data: value,
        state: state,
        classId: classId
      }
      this.visibleModalAbsensi = true
    },
    closeModalAbsensi() {
      this.visibleModalAbsensi = false
    },
    checkField(input) {
      return input || '-'
    },
    isScheduleExist(data) {
      return data && data.length > 0
    },
    toggleComplainModal() {
      this.visibleComplainModal = !this.visibleComplainModal
    },
    toggleQuestionModal() {
      this.visibleQuestionModal = !this.visibleQuestionModal
    },
    goTo(url) {
      this.$router.push(url)
    },
    goToNewTab(url) {
      window.open(url)
    },
    getComplainHistoryList() {
      this.getComplainHistory({
        classId: this.classId
      })
    },
    isCertificateEligible(type) {
      if (type === 'participation') {
        if (this.myClassDetail.exam_value >= 60) {
          return true
        } else {
          if (this.myClassDetail.exam_value > this.myClassDetail.pretest_value) {
            return true
          } else {
            return false
          }
        }
      } else if (type === 'competency') {
        return this.myClassDetail.exam_value >= 60 && this.myClassDetail.tugas_status
      }
    },
    isValidAbsen(date, index) {
      const validToOpenAbsensi = !this.checkDate(date)
      const validQuiz = this.validityCheck(index)
      return validToOpenAbsensi && validQuiz
    }
  }
}
