<template>
  <svg
    id="_x31_"
    enable-background="new 0 0 24 24"
    viewBox="0 0 24 24"
    :width="`${width}px` || '20px'"
    :height="`${height}px` || '20px'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="color"
      d="m15.5 24h-13c-1.379 0-2.5-1.122-2.5-2.5v-16c0-1.378 1.121-2.5 2.5-2.5h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.827 0-1.5.673-1.5 1.5v16c0 .827.673 1.5 1.5 1.5h13c.827 0 1.5-.673 1.5-1.5v-16c0-.827-.673-1.5-1.5-1.5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c1.379 0 2.5 1.122 2.5 2.5v16c0 1.378-1.121 2.5-2.5 2.5z"
    />
    <path
      :fill="color"
      d="m12.5 6h-7c-.827 0-1.5-.673-1.5-1.5v-2c0-.276.224-.5.5-.5h2.05c.232-1.14 1.243-2 2.45-2s2.218.86 2.45 2h2.05c.276 0 .5.224.5.5v2c0 .827-.673 1.5-1.5 1.5zm-7.5-3v1.5c0 .276.225.5.5.5h7c.275 0 .5-.224.5-.5v-1.5h-2c-.276 0-.5-.224-.5-.5 0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5c0 .276-.224.5-.5.5z"
    />
    <path
      :fill="color"
      d="m22 21c-.189 0-.362-.107-.447-.276l-1.289-2.578c-.173-.346-.264-.732-.264-1.118v-9.028c0-1.103.897-2 2-2s2 .897 2 2v9.028c0 .386-.091.772-.264 1.118l-1.289 2.578c-.085.169-.258.276-.447.276zm0-14c-.552 0-1 .449-1 1v9.028c0 .231.055.464.158.671l.842 1.683.842-1.683c.103-.207.158-.44.158-.671v-9.028c0-.551-.448-1-1-1zm1.289 10.922h.01z"
    />
    <path
      :fill="color"
      d="m14.5 10h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z"
    />
    <path
      :fill="color"
      d="m14.5 13h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z"
    />
    <path
      :fill="color"
      d="m14.5 16h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z"
    />
    <path
      :fill="color"
      d="m14.5 19h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5s-.224.5-.5.5z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
