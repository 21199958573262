<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 477.867 477.867"
    style="enable-background: new 0 0 477.867 477.867"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M238.933,0C106.974,0,0,106.974,0,238.933s106.974,238.933,238.933,238.933s238.933-106.974,238.933-238.933C477.726,107.033,370.834,0.141,238.933,0z M238.933,443.733c-113.108,0-204.8-91.692-204.8-204.8s91.692-204.8,204.8-204.8s204.8,91.692,204.8,204.8C443.611,351.991,351.991,443.611,238.933,443.733z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          :fill="color"
          d="M238.933,85.333c-9.426,0-17.067,7.641-17.067,17.067v119.467H102.4c-9.426,0-17.067,7.641-17.067,17.067S92.974,256,102.4,256h136.533c9.426,0,17.067-7.641,17.067-17.067V102.4C256,92.974,248.359,85.333,238.933,85.333z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    }
  }
}
</script>
